import React, { useState, useEffect } from "react"
import DiceRoller from "../../components/embedTools/diceRoller"
import { Box } from "../../components/commonElements"
import Header from "../../components/embedTools/header"
import CirclePlaceholder from "../../components/embedTools/circlePlaceholder"
import SeoEmb from "../../components/seo/seoEmb"
import DisableAd from "../../components/embedTools/disableAd"

const EmbedD8Page = () => {
  const [smallSize, setSmallSize] = useState(true)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") return
    const handleResize = () => {
      if (window.innerWidth < 300) {
        setSmallSize(true)
      } else {
        setSmallSize(false)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box backgroundColor="white" alignSelf="center" width="100%" zIndex="0">
      <DisableAd />
      <Header tool="d8" setShow={value => setShow(value)} />
      {show && (
        <>{smallSize ? <CirclePlaceholder /> : <DiceRoller diceType="d8" />}</>
      )}
    </Box>
  )
}

export default EmbedD8Page

export const Head = () => (
  <SeoEmb
    title="Embed D8 Dice Roller | FlipSimu"
    canonical="https://flipsimu.com/dice-roller/roll-d8/"
  />
)
